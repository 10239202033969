import axios from "axios";

axios.defaults.withCredentials = false;

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000,
});

// const penddingMap = new Map();

// const addPendding = (config) => {
//   config.cancelToken =
//     config.cancelToken ||
//     new axios.CancelToken((cancel) => {
//       if (!penddingMap.has(config.url)) {
//         penddingMap.set(config.url, cancel);
//       }
//     });
// };

// const removePendding = (config) => {
//   if (penddingMap.has(config.url)) {
//     let cancel = penddingMap.get(config.url);
//     cancel(config.url);
//     penddingMap.delete(config.url);
//   } else {
//     console.log("no:", config.url);
//   }
// };

service.interceptors.request.use(
  (config) => {
    let vendor_user_token = localStorage.getItem("vendor_user_token");
    config.headers["x-auth-token"] = vendor_user_token;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log(error);
    if (
      error.response &&
      error.response.status &&
      400 <= error.response.status < 500
    ) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message === "Invalid token."
      ) {
        console.log("Invalid token.");
        localStorage.clear();
        window.location.href = "/v2/login";
        return Promise.reject(error);
      }
      return Promise.resolve(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
