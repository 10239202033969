import request from './axios';

let vendor_user_token = localStorage.getItem('vendor_user_token')
let headers = { 'x-auth-token': vendor_user_token, 'Content-Type': 'application/json' };

// 搜索列表
export const searchLists = (method, module, params = '', pageNumber = 0, orderDir = 'desc') => {
    let url = '?orderDir=' + orderDir;
    if (pageNumber > 0)
        url += '&pageNumber=' + pageNumber;
    if (params)
        url += '&' + params;

    return request({
        url: 'vendor/' + module + url,
        method: method,
        headers: headers
    });
}

// 获取列表
export const getLists = (method, module, pageNumber = 0, orderDir = 'desc') => {
    let url = '?orderDir=' + orderDir;
    if (pageNumber > 0)
        url += '&pageNumber=' + pageNumber;

    return request({
        url: 'vendor/' + module + url,
        method: method,
        headers: headers
    });
}
// 获取数据
export const getData = (method, module, id) => {
    return request({
        url: 'vendor/' + module + '/' + id,
        method: method,
        headers: headers
    });
}
// 询盘数据
export const getDatas = (method, module) => {
    return request({
        url: module,
        method: method,
        headers: headers
    });
}
// 数据
export const postData = (method, module, data) => {
    return request({
        url: process.env.VUE_APP_V2_API_URL + module,
        method: method,
        headers: headers,
        data: data
    });
}
// 获取状态
export const getStatus = (method, module) => {
    return request({
        url: module,
        method: method,
        headers: headers
    });
}
// 渲染数据
export const renderData = (method, module) => {
    return request({
        url: 'vendor/' + module,
        method: method,
        headers: headers,
    })
}
// 更新数据
export const updateData = (method, module, id, data) => {
    return request({
        url: 'vendor/' + module + '/' + id,
        method: method,
        headers: headers,
        data: data
    });
}
// 创建数据
export const createData = (method, module, data) => {
    return request({
        url: 'vendor/' + module,
        method: method,
        headers: headers,
        data: data
    });
}
// 修改密码
export const changePassword = (method, module, data) => {
    return request({
        url: module,
        method: method,
        headers: headers,
        data: data
    });
}

// 设定状态
export const setStatus = (method, module, id, status = -1) => {

    let url = 'vendor/' + module + '/update-status/' + id;
    if (status >= 0) {
        url += '/' + status;
    }

    return request({
        url: url,
        method: method,
        headers: headers
    });
}

// 删除数据
export const deleteData = (method, module, id) => {
    return request({
        url: 'vendor/' + module + '/' + id,
        method: method,
        headers: headers
    });
}
// 删除图片
export const deleteDatas = (method, module, data) => {
    return request({
        url: 'vendor/' + module,
        method: method,
        headers: headers,
        data: data
    });
}
// 登录
export const login = (email, password) => {
    return request({
        url: 'auth/vendorLogin',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: { "email": email, "password": password }
    });
}
//注册
export const register = (method, module, data) => {
    return request({
        url: module,
        method: method,
        headers: headers,
        data: data
    });
}
//入驻
export const settled = (method, module, data) => {
    return request({
        url: module,
        method: method,
        headers: headers,
        data: data
    });
}
//上传
export const upload = (method, module, data) => {
    return request({
        url: module,
        method: method,
        headers: headers,
        data: data
    });
};
export const uploadoss = (method, module, data) => {
    let form = new FormData();
    console.log(data)
    form.append('key', data.key);
    form.append('OSSAccessKeyId', data.OSSAccessKeyId);
    form.append('policy', data.policy);
    form.append('signature', data.signature);
    form.append('success_action_status', data.success_action_status);
    form.append('file', data.file);
    return request({
        url: module,
        method: method,
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: form
    });
};
//修改入驻
export const settleds = (method, module, id, data) => {
    return request({
        url: 'vendor/' + module + '/' + id,
        method: method,
        headers: headers,
        data: data
    });
}

// 设置背景
export const getSetting = (method, module) => {
    return request({
        url: 'vendor/' + module,
        method: method,
        headers: headers
    });
}

// 更新设置
export const updateSetting = (method, module, data) => {
    return request({
        url: "vendor/" + module,
        method: method,
        headers: headers,
        data: data
    });
}

//发送验证码
export const sendCode = (method, module, data) => {
    return request({
        url: module,
        method: method,
        data: data
    });
}

export const getCountry = () => {
    return request({
       url: 'country',
       method: 'get',
       headers: headers 
    });
}

export const getStoreList = () => {
    return request({
       url: 'admin/store?orderDir=desc&pageSize=9999',
       method: 'get',
       headers: headers 
    });
}