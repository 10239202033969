<template>
  <div>
    <!-- Add category -->
    <el-row>
      <!-- <el-col :span="3">
        <el-button class="category">
          <i class="el-icon-upload2"></i>
          Add category
        </el-button>
      </el-col> -->
      <el-col :span="3">
        <el-button class="all">Select all</el-button>
      </el-col>
      <el-col :span="12">
        <el-button class="delete">delete</el-button>
      </el-col>
      <!-- <el-col :span="3">
        <el-button class="folder">New folder</el-button>
      </el-col>
      <el-col :span="3">
        <el-button class="use">Select use</el-button>
      </el-col> -->
    </el-row>
    <!-- picture -->

    <el-card class="mt">
      <div class="right">
        <ul>
          <li v-for="(item, index) in data" :key="index">
            <i
              class="el-dialog__close el-icon el-icon-close"
              @click="deleteImage(item._id)"
            ></i>
            <img :src="item.url" />
          </li>
        </ul>

        <div class="pagination-rounded">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="perPage"
            layout="total, prev, pager, next"
            :total="totalRows"
            jumper-label="dd"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { deleteData, getLists } from "../../helper/api";
export default {
  data() {
    return {
      totalRows: 0,
      currentPage: 0,
      perPage: 20,
      pageSizes: [1, 2, 3, 4, 5],
      data: [],
    };
  },
  created() {
    this.lists(this.currentPage);
  },
  methods: {
    lists(page) {
      getLists("GET", "image", page, "desc", this.perPage).then((res) => {
        this.data = res.data.rows;
        this.totalRows = res.data.count;
      });
    },
    deleteImage(id) {
      deleteData("DELETE", "image", id).then((res) => {
        this.$message({
          message: res.message,
          type: "success",
        });

        this.lists(this.currentPage);
      });
    },
    handleSizeChange(val) {
      this.PageSize = val;
      this.currentPage = 1;
      this.lists(this.currentPage);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.lists(this.currentPage);
    },
  },
};
</script>

<style scoped>
.category {
  width: 150px;
  height: 45px;
  background: #ff0a0a;
  color: #fff;
}
.all {
  width: 150px;
  height: 45px;
  background: gray;
  color: #fff;
}
.delete {
  width: 150px;
  height: 45px;
  background: gray;
  color: #fff;
}
.folder {
  width: 150px;
  height: 45px;
  background: gray;
  color: #fff;
}
.use {
  width: 150px;
  height: 45px;
  background: #ff0a0a;
  color: #fff;
}
.left {
  width: 100px;
  border-right: 1px solid gray;
}
.picture1 {
  width: 80px;
  height: 120px;
  border-bottom: 2px solid gray;
}
.picture1 .icon {
  font-size: 36px;
  color: #ff0a0a;
}
.picture1 .p1 {
  font-size: 16px;
  color: #ff0a0a;
  margin-left: 10px;
}
.picture2 {
  width: 80px;
  height: 400px;
  margin-top: 40px;
}
.picture2 .icon {
  font-size: 36px;
}
.picture2 .p2 {
  font-size: 16px;
  margin-left: 10px;
}
.mt .right ul {
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;
}
.mt .right ul li {
  float: left;
  width: 190px;
  height: 190px;
  margin: 10px;
  border: 1px #cccccc solid;
  border-radius: 10px;
  overflow: hidden;
}
.mt .right ul li img {
  width: 190px;
  height: 190px;
}
.mt .right ul li .el-icon-close {
  position: absolute;
  border: 1px red solid;
  margin-left: 160px;
  margin-top: 10px;
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  color: #ffffff;
}
</style>